.fc-theme-standard .fc-scrollgrid {
    border: 1px solid #eff4f9;
  }
  
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: 1px solid #eff4f9;
  }
  
  //events
  .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    min-height: 2em;
    position: relative;
    width: 25px !important;
    height: 15px !important;
  }
  
  .fc-h-event .fc-event-title-container {
    height: 15px;
    margin: -2px;
  }
  
  .fc .fc-daygrid-event-harness {
    width: 15px;
  }
  
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 2px;
    border: none;
  }
  
  .fc-direction-ltr .fc-daygrid-event.fc-event-end,
  .fc-direction-rtl .fc-daygrid-event.fc-event-start {
    margin-right: 2px;
    border: none;
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }
  
  .fc .fc-daygrid-day-number {
    padding: 6px 10px 4px 4px;
  }
  
  .fc .fc-scrollgrid-liquid {
    height: 85%;
  }
  
  .fc-day {
    .fc-col-header-cell-cushion {
      color: #0045b9 !important;
      font-weight: 500;
    }
  }
  
  .fc-day-sat,
  .fc-day-sun {
    color: #99afde !important;
  
    .fc-col-header-cell-cushion {
      color: #99afde !important;
    }
  }
  .fc .fc-toolbar .fc-button {
    .fc-icon-chevron-left {
      font-size: 1.1rem;
    }
  
    .fc-icon-chevron-right {
      font-size: 1.1rem;
    }
  }
  
  .fc .fc-toolbar-title {
    font-size: 1.5em;
    margin: 0;
  }
  
  
  .fc .fc-button-primary:disabled {
    display: none;
  }
  
  .fc .fc-toolbar .fc-button-group .fc-button:last-child {
    background-color: #0045b9;
    margin-right: 5em;
    padding: 1em;
  }
  
  .fc .fc-bg-event .fc-event-title {
    font-size: 12px;
    margin: 0.5em;
    padding: 22px;
    font-weight: bold;
    color: black;
    font-style: normal;
  }
  
  .fc .fc-toolbar .fc-button-group .fc-button:first-child {
    background-color: #0045b9;
    margin-right: 5em;
    padding: 1em;
  }
  
  .fc .fc-view-harness {
    width: 95%;
  }
  
  .fc .fc-daygrid-day.fc-day-today {
    background: var(--Neutral-Gray, #f0f2f4);
    color: #0045b9;
  }
  
  .fc table {
    border-spacing: unset;
    font-size: 0.88em;
  }
  
  .mat-card > :last-child:not(.mat-card-footer),
  .mat-card-content > :last-child:not(.mat-card-footer) {
    margin-bottom: 0;
  }
  
  .fc .fc-button:not(:disabled) {
    display: table-row;
    background: #0045b9;
    border: 1px solid #0045b9;
    padding: 5px 9px 5px 9px;
  }
  
  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 1em;
    margin-top: -1em;
    width: 95%;
  }