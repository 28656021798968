button:focus {
    outline: none;
}

button:hover {
    transform: scale(1.03, 1.03);
}

.blue-button {
    background: var(--blue-gradient);
    color: var(--white-color);
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    border: none;
    cursor: pointer;
}

.grey-disabled-button{
    background-color: var(--light-grey-color);
    color: var(--white-color);
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    border: none;
    cursor: pointer;
}

.pink-button {
    background: var(--pink-gradient);
    color: var(--white-color);
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    border: none;
    cursor: pointer;
}

.blue-border-button {
    background: var(--white-color);
    color: var(--primary-blue-color);
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    border: none;
    border: 1.5px solid var(--border-blue-color);
}

.white2-button{
    background: var(--white-color);
    color: var(--Nebula-color);
    border-radius: 3px;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    border: none;
    border: 1px solid var(--stardust-color);
}

.white3-button{
    background: var(--white-color);
    color: var(--dark-grey-color);
    border-radius: 3px;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    border: none;
    border: 2px solid var(--light-grey-color);
}

.grey-border-button{
    background: var(--white-color);
    color: var(--primary-blue-color);
    border-radius: 6px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    border: none;
    border: 2px solid var(--neutral-grey-color);
}

.pink-text-only-btn{
    background: var(--white-color);
    color: var(--Nebula-color);
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    border: none;
}