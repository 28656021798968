.pagination {
  justify-content: flex-end !important;
  position: relative;
  bottom: 0;
  margin-top: 15px;
}

.ngx-pagination .current {
  padding: 0.1875rem 0.625rem;
  background: var(--primary-blue-color) !important;
  color: #fefefe;
  cursor: default;
}

.ngx-pagination .disabled {
  padding: 0.1875rem 0.625rem;
  color: #999999 !important;
  cursor: default;
}

.ngx-pagination a,
.ngx-pagination button {
  color: var(--black-color) !important;
  display: block;
  padding: 0.1875rem 0.625rem;
  border-radius: 0;
}

:host ::ng-deep .ngx-pagination .pagination-previous a:before {
  display: none !important;
}

:host ::ng-deep .ngx-pagination .pagination-previous.disabled:before {
  display: none !important;
}

:host ::ng-deep .ngx-pagination .pagination-next a:after {
  display: none !important;
}

:host ::ng-deep .ngx-pagination .pagination-next.disabled:after {
  display: none !important;
}

:host ::ng-deep .ngx-pagination {
  margin: 0px !important;
}