/* You can add global styles to this file, and also import other style files */

:root {
  --primary-blue-color: #0036AC;
  --secondary-blue-color: #016BD7;
  --border-blue-color: #99AFDE;
  --blue-gradient: linear-gradient(8.86deg, #016BD7 6.49%, #0036AC 410.05%);
  --blue-pink-gradient: linear-gradient(124deg, #013CB1 33.62%, #FF8396 100%);
  --pink-gradient: linear-gradient(205.25deg, #6B3176 -101.9%, #B92076 130.72%);
  --pink-color: #FF8396;
  --stardust-color: #FFCDD5;
  --black-color: #000000;
  --dark-grey-color: #3D3D3D;
  --medium-grey-color: #808080;
  --medium-light-grey-color: #999999;
  --light-grey-color: #DADADA;
  --neutral-grey-color: #F0F2F4;
  --white-color: #FFFFFF;
  --sunspot-color: #FC8034;
  --venus-color: #F9B32C;
  --aurora-color: #0AB4AC;
  --Carolina-color: #4B9CD3;
  --Cosmic-color: #11214C;
  --Purple-color: #6B3176;
  --Nebula-color: #B92076;
  --font-family: 'Nunito'; //font-family
}

@import '~ngx-toastr/toastr.css';
@import 'src/assets/css/button.scss';
@import 'src/assets/css/form-field.scss';
@import 'src/assets/css/calendar.scss';
@import 'src/assets/css/expansion-panel.scss';
@import 'src/assets/css/pagination.scss';

#toast-container>div {
  opacity: 1;
}

html {
  font-size: 1vw;
}

html,
body {
  height: 100%;
}

body {
  background-color: var(--neutral-grey-color);
  margin: 0;
  font-family: Nunito !important;
}

::-webkit-scrollbar {
  width: 3px !important;
  height: 1px !important;
}

*:focus {
  outline: none !important;
}

.input:-internal-autofill-selected {
  background-color: transparent !important;
}

.backdropClass {
  background-color: black;
}

.panelClassFeedbackGenerationForm .mat-dialog-container {
  background-color: var(--neutral-grey-color);
  overflow-x: hidden !important;
}

/* Gobal CSS for Mat tab starts here */
.mat-tab-header {
  color: var(--primary-blue-color);
  border: 1px solid var(--light-grey-color);
  background: var(--white-color);
  height: 40px;
}

.mat-tab-label-active {
  color: var(--primary-blue-color);
  font-weight: 500;
}

.mat-tab-label .mat-tab-link {
  letter-spacing: 1.2px;
  cursor: pointer;
  opacity: 1 !important;
  width: 200px;
  font-size: 15px;
}

.mat-tab-label-content {
  margin-bottom: 5px;
}

.mat-expansion-panel-header, .mat-expansion-panel-content{
  font-family: var(--font-family);
}
//global css for mat-icon

.mat-icon-unfocused {
  color: #666666;
}

.mat-icon {
  color: var(--primary-blue-color);
}

/* global setting for tooltip */

.mat-drawer-content {
  background: var(--neutral-grey-color) !important;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow-x: hidden !important;
}

.full-width {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.border-primary {
  border: 1px solid var(--primary-color) !important;
}

.text-color-white {
  color: white;
}

.bg-color-primary {
  background-color: var(--primary-color);
}

.text-color-primary {
  color: var(--primary-color);
}

.border-color-primary {
  border: 2px solid var(--primary-color);
}

.bg-color-warning {
  background-color: var(--warn-color);
}

.text-color-warning {
  color: var(--warn-color);
}

.border-color-warning {
  border: 2px solid var(--warn-color);
}

.bg-color-green {
  background: var(--green-color);
}

.text-color-green {
  color: var(--green-color);
}

.border-color-green {
  border: 2px solid var(--green-color);
}

.bg-color-yellow {
  background: var(--yellow-color);
}

.text-color-yellow {
  color: var(--yellow-color);
}

.border-color-yellow {
  border: 2px solid var(--yellow-color);
}

.p-rating .p-rating-icon.pi-star {
  color: var(--primary-color) !important;
}

/* Track */
* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgb(235, 243, 235);
  border-radius: 10px;
  opacity: 0.2;
}

/* Handle */
* ::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
  opacity: 0.2;
}

/* Handle on hover */
* ::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
  opacity: 0.4;
}

/*CSS for dialog boxes*/
/*START*/
.mat-drawer-container {
  background: #FFF !important;
}

.icon{
  font-size: 24px;
  // margin-bottom: 3rem;
  cursor: pointer;
}
.mat-raised-button{
  border-radius: 2px !important;
}

/*ENDS*/

/**FG-Card - CSS**/
.img-col {
  border-right: 1px solid #e9ecef;
}

.fg-card-header {
  font-size: 20px !important;
  line-height: 28px !important;
  transition: all .5s;
}

.mat-tab-group.mat-primary,
.mat-card,
.mat-list-item,
.mat-tab-label,
.mat-tab-link,
.mat-typography h2,
.mat-menu-item,
.mat-typography h1,
.mat-option-text,
.mat-button .mat-button-wrapper {
  font-family: 'Nunito' !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  border-radius: 4px;
  background: var(--blue-gradient);
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: var(--primary-blue-color);
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--primary-blue-color);
}


.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  border-radius: 4px;
  background: var(--blue-gradient);
}


// mat dialog box global css changes
.mat-dialog-container {
  border-radius: 5px;
  box-shadow: none;
  padding: 0px !important;
  font-family: 'Nunito';
}

.mat-select-arrow {
  color: var(--primary-blue-color) !important;
}

.mat-drawer-transition .mat-drawer-content::-webkit-scrollbar {
  width: 5px;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--primary-blue-color);
}

::ng-deep .mat-form-field.mat-focused .mat-select-arrow {
  color: var(--primary-blue-color) !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--primary-blue-color) !important;
}

::ng-deep .mat-radio-button.mat-accent .mat-radio-inner-circle,
::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
::ng-deep .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
::ng-deep .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
::ng-deep .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element,
::ng-deep.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  background-color: var(--neutral-grey-color) !important;
}

::ng-deep .mat-dialog-container {
  font-family: 'Nunito';
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--primary-blue-color) !important;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {

  color: var(--primary-blue-color) !important
}

button.mat-menu-item {
  width: 100%;
  color: var(--black-color) !important
}

.mat-select-value {
  color: var(--dark-grey-color);
}

.mat-select-disabled .mat-select-value-text {
  color: #999999 !important;
}

.mat-select-value-text {
  color: var(--dark-grey-color);
  font-weight: 500;
}

.mat-form-field.mat-focused .mat-select-arrow {
  color: var(--primary-blue-color) !important;
}

.mat-option.mat-active {
  background: var(--neutral-grey-color) !important;
  color: var(--primary-blue-color) !important
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--neutral-grey-color) !important
}

.mat-calendar-body-selected {
  background-color: var(--primary-blue-color) !important;
  color: white;
}

.mat-calendar-body-active {
  .mat-calendar-body-today {
    background-color: var(--primary-blue-color) !important;
    color: white;
  }
}

.mat-datepicker-toggle-default-icon:hover {
  color: #0045B9;
}

.mat-datepicker-toggle-active {
  color: #0045B9;
}

.fg-card-text {
  display: block;
  font-size: smaller;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 2px 0;
}

.fg-card {
  background: #FFF;
  height: 210px;
  position: relative;
  border: 1px solid var(--primary-color);
}

.fg-card-feedback {
  background: #FFF;
  height: 150px;
  width: 230px;
  position: relative;
  border: 1px solid var(--primary-color);
}

.fg-card:hover {
  -webkit-box-shadow: 0 5px 20px -10px var(--primary-color);
  box-shadow: 0 5px 20px -10px var(--primary-color);
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.fg-card:hover .fg-card-header {
  color: var(--primary-color);
}

.fg-card-footer {
  position: absolute;
  bottom: 5px;
}

/**FG-Card - CSS**/

/* NEXT */

/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */

.clearfix {
  clear: both;
}

.pointer {
  cursor: pointer;
}

.ucfirst {
  text-transform: capitalize;
}

.min300 {
  min-height: 300px
}

.app-box {
  padding: 10px;
  margin-bottom: 20px;
  height: 230px;
  background: #b5e8d4;
  overflow: hidden;
  box-shadow: 1px 1px 4px #ccc;
}

.app-box:hover {
  box-shadow: 1px 1px 4px #999;
}

.app-box .remove {
  position: absolute;
  right: 21px;
  top: 0px;
  color: #545454;
  display: none;
}

.app-box:hover .remove {
  display: block;
}

.inline-btn {
  background: #00b970;
  padding: 1px 5px;
  color: white;
  display: inline-block;
  margin-bottom: 5px;
}

.box {
  padding: 10px;
  margin-bottom: 10px;
  overflow: auto;
  box-shadow: 1px 1px 4px #ddd;
}

.form-group .form-control {
  font-size: inherit;
  background-color: inherit;
}

.dndDropArea {
  padding: 10px;
  /* border: dotted #ddd 2px; */
}

.dndDropArea.empty {
  min-height: 200px;
  border: dotted #ddd 2px;
}

.field {
  padding: 0px;
  margin-bottom: 0px;
  position: relative;
}

.field:hover {
  box-shadow: 1px 1px 4px #ddd;
}

.toggle-Wrapper {
  padding: 15px;
  background: #d6d6d629;
}

.dndHandle {
  cursor: pointer;
  color: #999;
}

.dndDraggingSource {
  opacity: 0.3;
}

.dndPlaceholder {
  background: rgb(206, 238, 238);
  border-radius: 2px;
  padding: 20px;
  margin-bottom: 10px;
}

.field .fa {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 24px;
  vertical-align: middle;

}

.red {
  color: #d9534f
}

.fa-trash {
  color: #d9534f
}

.marginT60 {
  margin-top: 60px;
}

.color-red {
  color: red;
}

.report-block {
  padding: 10px;
  background: #f8f8f8;
  margin-bottom: 20px;
}

.report-block .row:nth-child(even) {
  background: #fff;
}

.ftheme {
  width: 100%;
}

.dndDropArea .field .fa {
  opacity: 0;
}

.dndDropArea .field:hover .fa {
  opacity: 1;
}

.colorpick {
  height: 25px;
  background: white;
  border: none;
}

.carousel-wrap {
  width: 1000px;
  margin: auto;
  position: relative;
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid black;
  background-image: none;
}

.carousel-control-next-icon:after {
  content: '>';
  font-size: 55px;
  color: #00BFD5;
  float: right;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: #00BFD5;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #00BFD5;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 1;
  transition: opacity .6s ease;
}

.mat-drawer-inner-container {
  width: 100%;
  height: 100%;
  overflow-x: hidden !important;
  background-color: var(--white-color);
}

.mat-drawer-transition .mat-drawer-content {
  overflow-x: hidden !important;
}

// employee css starts

.mat-tab-body-content {
  overflow-x: hidden !important;
}

.mat-ink-bar {
  position: absolute;
  bottom: 0;
  height: 3px !important;
  transition: 500mscubic-bezier(0.35, 0, 0.25, 1);
}

.mat-menu-content:not(:empty) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.mat-drawer-inner-container {
  overflow-x: hidden !important;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  opacity: 0.1;
  margin-left: 5px;
}

*:hover::-webkit-scrollbar {
  width: 8px;
  opacity: 0.5;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 69, 185, 0.5);
  border-radius: 10px;
  opacity: 0.1;
}

*:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 69, 185, 1);
  opacity: 0.5;
  width: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(50, 119, 235, 1);
}

.mat-progress-bar-fill::after {
  background-color: var(--primary-blue-color) !important;
}

.toast-success {
  padding-left: 80px;
  text-align: center;
  background-position: 35% !important;
  background: var(--primary-blue-color);
  border: 2px solid var(--primary-blue-color) !important;
  font-size: 16px !important;
  width: 120px;
}

::ng-deep .mat-select {
  font-family: 'Nunito';
}

::ng-deep .mat-tooltip {
  width: auto;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid var(--primary-blue-color);
  font-weight: 400;
  color: #3d3d3d !important;
  font-family: Nunito;
  position: relative;
  max-height: 68px;
  margin: 3px 14px 0px 14px !important;
  overflow-y: scroll !important;
  &::after {
    width: 20;
    height: 20;
    content: '';
    position: absolute;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solidvar(--primary-blue-color);
  }

  &.below {
    overflow-y: scroll;
    margin-top: 1rem;

    &:after {
      top: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(0);
    }
  }

  &.above {
    overflow-y: scroll;
    &:after {
      bottom: -0.5rem;
      right: calc(50% - 0.5rem);
      transform: rotate(180deg);
    }
  }

  &.right {
    overflow-y: scroll;
    margin-left: 1rem;

    &:after {
      left: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(270deg);
    }
  }

  &.left {
    overflow-y: scroll;
    margin-right: 1rem;

    &:after {
      right: -0.75rem;
      top: calc(50% - 0.25rem);
      transform: rotate(90deg);
    }
  }

}

// employee css ends

@media (min-width: 1250px) {
  .feedbackform-overlay.cdk-overlay-pane {
    width: 95vw;
  }
}

@media (min-width: 1000px) and (max-width: 1250px) {
  .feedbackform-overlay.cdk-overlay-pane {
    width: 1000px;
  }
}

.mat-icon-color,
svg {
  color: #666666;

}

.mat-icon-unfocused {

  color: #666666;

}

//error screen css
.main-error-container {
  height: 100vh;
}

.error-container {
  display: flex;
  height: 100%;
  align-items: center;
}

.error-logo-container {
  width: 252px;
  height: 400px;
  border-radius: 0px 200px 200px 0px;
  background: linear-gradient(181.1deg, #CCE1F7 0.95%, #FFE7EB 134.67%);
  align-items: center;
  display: flex;
}

.error-logo {
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.error-details-container {
  flex-grow: 1;
}

.error-details {
  margin-left: 50px;
}

.error-name {
  font-family: Nunito;
  font-size: 32px;
  font-weight: 500;
  color: var(--primary-blue-color);
}

.error-bar {
  width: 866px;
  border: 0.5px solid var(--medium-grey-color);
  margin: 20px 0px;
}

.error-desc {
  font-size: 18px;
  font-weight: 500;
  width: 85%;
  line-height: 24px;
}

.error-white-btn {
  margin-top: 20px;
  width: 114px;
  height: 26px;
  border-radius: 2px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: 1px solid var(--border-blue-color);
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-blue-color);
}