:host ::ng-deep .mat-accordion .mat-expansion-panel {
    box-shadow: none;
}

:host ::ng-deep .mat-expansion-panel {
    border: 1px solid #f0f2f4;
    border-radius: 2px !important;
    box-shadow: none;
}

:host ::ng-deep .mat-expansion-panel:hover {
    border: 1px solid #99afde;
}

::ng-deep .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
.mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background: var(--white-color) !important;
}

.mat-expansion-indicator::after {
    color: var(--primary-blue-color);
}

.mat-expansion-panel {
    margin: 0;
}

.mat-expansion-panel-header {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
    display: flex;
    flex-grow: 1;
    margin-right: 0px !important;
    align-items: center;
}

.mat-expansion-panel-body {
    padding: 0 12px 16px !important;
}