.mat-form-field,
.mat-label {
  font-family: var(--font-family) !important;
  color: var(--dark-grey-color);

}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--primary-blue-color) !important;
}

.mat-form-field-flex>.mat-form-field-infix {
  padding: 0.8em 0px !important;
  border-top: 0.678em solid transparent;
}

.mat-form-field-empty.mat-form-field-label {
  color: #999999 !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.3em) scale(0.68);
  color: var(--primary-blue-color);
}

.mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline-thick,
.mat-form-field.mat-form-field-invalid .mat-form-field-label,
.mat-error,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: var(--Nebula-color) !important;
}

.mat-form-field.mat-form-field-invalid .mat-select-arrow {
  color: var(--Nebula-color) !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: var(--light-grey-color) !important;
  opacity: 100 !important;
}

.mat-input-element {
  caret-color: var(--primary-blue-color) !important;
}

.mat-form-field-required-marker {
  color: var(--primary-blue-color) !important
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: var(--primary-blue-color) !important;
}


.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--primary-blue-color) !important;
  border: var(--primary-blue-color) !important;
  border-radius: 2px !important;
}

.mat-form-field-underline.mat-focused {
  background-color: var(--primary-blue-color) !important;
  border: var(--primary-blue-color) !important;

}

/* Apply styles to the label wrapper */
.mat-form-field-label-wrapper {
  color: var(--primary-blue-color) !important;
  /* Set to your primary color */
}

:host ::ng-deep .mat-form-field-wrapper {
  padding: 0px !important;
  height: 30px;
  margin: 0px !important;
}

:host ::ng-deep .mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0 !important;
  border-top: 6px solid transparent !important;
}

:host ::ng-deep .mat-form-field-flex {
  height: 45px !important;
}

::ng-deep .mat-select-panel {
  min-width: 100% !important;
  border: 2px solid;
  border-image: linear-gradient(123.61deg, #013cb1 33.62%, #ff8396 100%);
  border-image-slice: 1;

}

::ng-deep .mat-select-panel-wrap {
  position: relative;
  top: 20px;
  left: 20px;
}

::ng-deep .mat-option-text {
  font-size: 14px;
  width: 25px;
}

::ng-deep mat-select-value {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 13px;
}


::ng-deep .mat-select-panel:not([class*="mat-elevation-z"]) {
  min-width: 100% !important;
  border-radius: 0px !important;
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
  width: auto !important;
  font-family: var(--font-family);
  border: 2px solid !important;
  border-image: linear-gradient(123.61deg, #013cb1 33.62%, #ff8396 100%) !important;
  border-image-slice: 1 !important;
}

::ng-deep .mat-menu-panel {
  font-family: var(--font-family);
  border: 2px solid !important;
  border-image: linear-gradient(123.61deg, #013cb1 33.62%, #ff8396 100%) !important;
  border-image-slice: 1 !important;
}